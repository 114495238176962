import constant from "@/constant";
import testClient from "@/api/testClient";
import { useMonitorLogging } from '@/core/useMonitor/logging';
const { closeLostEventAfterHalting } = useMonitorLogging();

export const MonitoringModule = {
    namespaced: true,
    state: {
        error: null,
    },
    mutations: {
        SET_MONITORING_ERROR(state, error) {
            state.error = error;
        },
        CLEAR_MONITORING_ERROR(state) {
            state.error = null;
        }
    },
    actions: {
        haltTest({ commit, dispatch }, error) {
            dispatch('Test/setBookmarkModalVisibility', false, { root: true });
            commit('SET_MONITORING_ERROR', error);
        },
        unHaltTest({ commit }) {
            closeLostEventAfterHalting().then(() => {
                commit('CLEAR_MONITORING_ERROR');
            });
        },
        async submitEvent({ commit, rootState, dispatch }, event) {
            const response = await testClient.submitMonitorEvent(event)
            return response.data.data['total_lost_events'];
        },
    },
    getters: {
        isTestHalted: state => state.error !== null,
        isMonitoringBrowserEnabled(state, getters, rootState) {
            return rootState.Test.settings.test.proctor_user_monitor_mode;
        },
        isMonitoringBrowser(state, getters, rootState) {
            return getters.isMonitoringBrowserEnabled &&
                rootState.Test.test_state === constant.STATUS_TEST_IN_PROGRESS;
        },
        isMonitoringCameraEnabled(state, getters, rootState) {
            return rootState.Test.settings.test.proctor_camera_mode;
        },
        isMonitoringCamera(state, getters, rootState) {
            return getters.isMonitoringCameraEnabled &&
                rootState.Test.test_state === constant.STATUS_TEST_IN_PROGRESS;
        },
    },
};

