<template>
  <div class="camera-instructions">
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col>
          <h3 class="sub-title">{{ language['test_information_important'] }}</h3>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p>Todo build and include re-usable Camera component</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import { IonCol, IonGrid, IonRow } from '@ionic/vue';
import { useStore } from 'vuex';

const emit = defineEmits([
  'complete',
]);

const store = useStore();
const data = reactive(store.state.Test.data['test_intro']);
const settings = reactive(store.state.Test.settings['test']);
const language = reactive(store.getters.languageSet);

onMounted(() => {
  emit('complete');
});
</script>

<style lang="scss" scoped>

</style>