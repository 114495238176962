import {createStore} from 'vuex'
import {AuthModule} from './auth'
import {TestModule} from './test'
import {UserModule} from './user'
import {ErrorModule} from "./error";
import {LanguageModule} from "./language";
import {MonitoringModule} from "./monitoring";
import ThemeData from "./model/themeData";
import global_methods from "../utils/globalFunctions";
import constant from "../constant";

export default createStore({
    state: {
        'initial_load': null,
        'load_from_server': false,
        'print_button_clicked': false,
        'google_ads_script_mount': false,
        'google_ads_object': null,
        'iframe_mode': false,
        'test_auto_submit_timer': null,
        'iframe_attempts': null,
        'offline_status': null,
        'restart_url': null,
        'reach_sim_user_limit': false,
        'track_from_admin_app': null, //To track where the admin user from admin app.
        'app_variables': null
    },


    // Mutations are functions that effect the state. Never called by developer, it is called by actions function
    mutations: {
        UPDATE_LOAD_STATUS(state) {
            if (state.initial_load == null) {
                state.initial_load = true;
            } else {
                state.initial_load = false;
            }
        },
        SET_LOAD_FROM_SERVER(state) {
            state.load_from_server = true;
        },
        SET_PRINT_BUTTON_CLICKED(state) {
            state.print_button_clicked = true;
        },
        UNSET_PRINT_BUTTON_CLICKED(state) {
            state.print_button_clicked = false;
        },
        SET_GOOGLE_ADS_SCRIPT_MOUNTED(state) {
            state.google_ads_script_mount = true;
        },
        SET_GOOGLE_ADS_OBJECT(state, obj) {
            state.google_ads_object = obj;
        },
        SET_IFRAME_MODE(state) {
            state.iframe_mode = true;
        },
        SET_TEST_AUTO_SUBMIT_TIMER_OBJ(state, timer_obj) {
            state.test_auto_submit_timer = timer_obj
        },
        CLEAR_TEST_AUTO_SUBMIT_TIMER(state) {
            if (state.test_auto_submit_timer != null) {
                clearInterval(state.test_auto_submit_timer);
            }
        },
        SET_IFRAME_ATTEMPTS(state, attempts) {
            state.iframe_attempts = attempts;
        },
        SET_OFF_LINE_STATUS(state, offline_status) {
            state.offline_status = offline_status;
        },
        SET_RESTART_URL(state, restart_url) {
            state.restart_url = restart_url;
        },
        SET_TRACK_FROM_ADMIN_WEBSITE(state, trk){

            state.track_from_admin_app = trk;

        },
        SET_REACH_SIM_USER_LIMIT(state, status){
            state.reach_sim_user_limit = status;
        },
        SET_APP_GLOBAL_VARIABLES(state, variables){
            state.app_variables = variables
        }
    },


    // Actions are functions that you call through your application that call mutations.
    actions: {
        updateLoadStatus({commit}) {
            commit('UPDATE_LOAD_STATUS');
        },
        setLoadFromServer({commit}) {
            commit('SET_LOAD_FROM_SERVER');
        },
        setPrintButtonClicked({commit}) {
            commit('SET_PRINT_BUTTON_CLICKED');
        },
        unsetPrintButtonClicked({commit}) {
            commit('UNSET_PRINT_BUTTON_CLICKED');
        },
        setGoogleAdsScriptMounted({commit}) {
            commit('SET_GOOGLE_ADS_SCRIPT_MOUNTED');
        },
        setGoogleAdsObject({commit}, {google_ads_obj}) {
            commit('SET_GOOGLE_ADS_OBJECT', google_ads_obj);
        },
        setIframeMode({commit}) {
            commit('SET_IFRAME_MODE');
        },
        setTestAutoSubmitTimerObj({commit}, timer_obj) {
            commit('SET_TEST_AUTO_SUBMIT_TIMER_OBJ', timer_obj);
        },
        clearTestAutoSubmitTimer({commit}) {
            commit('CLEAR_TEST_AUTO_SUBMIT_TIMER');
        },
        setIframeAttempts({commit}, attempts) {
            commit('SET_IFRAME_ATTEMPTS', attempts);
        },
        setOfflineStatus({commit}, offline_status) {
            commit('SET_OFF_LINE_STATUS', offline_status);
        },
        setRestartUrl({commit}, restart_url) {
            commit('SET_RESTART_URL', restart_url);
        },
        setTrackFromAdminApp({commit}, track){

            commit('SET_TRACK_FROM_ADMIN_WEBSITE', track);
        },
        setReachSimUserLimit({commit}, status){
            commit('SET_REACH_SIM_USER_LIMIT', status)
        },
        setAppGlobalVariables({commit}, variables){
            commit('SET_APP_GLOBAL_VARIABLES', variables)
        }
    },

    // Modulising stores - spreading stores into different files
    modules: {
        Auth: AuthModule,
        Test: TestModule,
        User: UserModule,
        Error: ErrorModule,
        Language: LanguageModule,
        Monitoring: MonitoringModule,
    },

    getters: {
        userFullName: (state) => {
            let language = state.Language.data;
            let language_code = 'en'; // default language code
            if (language != null) {
                language_code = language.code;
            }
            let reverse_order = global_methods.namesInReverseOrder(language_code)

            let user = state.User.user;
            let full_name = '';


            if (reverse_order) {
                if (user.last_name) {
                    full_name = full_name + user.last_name;
                }

                if (user.first_name) {
                    // Korean  and Chinese names do not put space between first name and last name
                    // Japanese puts middle dot between first name and last name
                    if (full_name != '' && language_code == constant.LANG_CODE_JAPAN) {
                        full_name = full_name + "&#183";
                    }
                    full_name = full_name + user.first_name;
                }
            } else {
                if (user.first_name) {
                    full_name = full_name + user.first_name;
                }

                if (user.last_name) {
                    if (full_name != '') {
                        full_name = full_name + ' ';
                    }
                    full_name = full_name + user.last_name;
                }
            }
            return full_name;
        },
        loginSetting: (state) => {
            return state.Test.settings.login;
        },
        testState: (state) => {
            return state.Test.test_state;
        },
        testPageContents: (state) => {
            let test = state.Test.data.test
            return test.getCurrentPage();
        },
        testPageUserAnswers: (state) => {
            let test = state.Test.data.test
            return test.getCurrentPageUserAnswer();
        },
        getCurrentTestPageNumber: (state) => {
            return state.Test.data.test.current_page;
        },
        getTotalPageNumber: (state) => {
            return state.Test.data.test.total_page;
        },
        scoreId: (state) => {
            if (state.Test.data.test && 'score_id' in state.Test.data.test) {
                return state.Test.data.test.score_id;
            }
        },
        quizId: (state) => {
            return state.Test.quiz_id;
        },
        groupType: (state) => {
            let settings = state.Test.settings;
            if (settings != null && settings.test != null) {
                return settings.test.group_type;
            }
        },
        groupId: (state) => {
            let settings = state.Test.settings;
            if (settings != null && settings.test != null) {
                return settings.test.group_id;
            }
        },
        testId: (state) => {
            let settings = state.Test.settings;
            if (settings != null && settings.test != null) {
                return settings.test.test_id;
            }
        },
        getNumberOfQuestionInTest: (state) => {
            let test = state.Test.data.test;
            return test.getNumberOfQuestionInTest();
        },
        getNumberOfQuestionInSection: (state) => {
            let test = state.Test.data.test;
            return test.getNumberOfQuestionInSection();
        },
        getPageFeedback: (state) => {
            let test = state.Test.data.test;
            return test.getPageFeedback();
        },
        testSettings: (state) => {
            return state.Test.settings.test;
        },
        testResultSettings: (state) => {
            return state.Test.data.result.settings;
        },
        theme: (state) => {
            let theme = state.Test.settings.theme;
            if (theme == null) {
                theme = new ThemeData({});
            }
            return theme;
        },
        languageSet: (state) => {
            let language = state.Language.data;
            return language;
        },
        reverseText: (state) => {
            return state.Language.reverse_direction;
        },
        displayErrorModal: (state) => {
            return state.Error.error.display_modal;
        },
        displayErrorPage: (state) => {
            return state.Error.error.full_page;
        },
        previewMode: (state) => {
            if (state.Test.settings.test != null) {
                return state.Test.settings.test.preview_mode;
            }
            return false;
        },
        testOfflineSoonMessage: (state) => {
            return state.Test.data.test.offline_soon_message;
        },
        offlineStatus: (state) => {
            return state.offline_status;
        },
        errorMessage: (state) => {
            return state.Error.error.message;
        },
        errorType: (state) => {
            return state.Error.error.type;
        },
        remainingTime: (state) => {
            return state.Test.settings.test.remaining_time;
        },
        timeoutTimestamp: (state) => {
            return state.Test.settings.test.timeout_timestamp;
        },
        accessToken: (state) => {
            return state.Auth.token;
        },
        trackFromAdminApp: (state) => {
            return state.track_from_admin_app;
        },
        reachSimUserLimit: (state)=>{
            return state.reach_sim_user_limit;
        }
    }
})
