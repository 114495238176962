import {ref, watch} from 'vue';
import {useMouseOut} from '@/core/useMouseOut';
import {useMonitorLogging} from '@/core/useMonitor/logging';
import {useDocumentVisibility, useEventListener, useFullscreen, useTimeoutFn, useWindowFocus,} from '@vueuse/core';

export const MonitorBrowserError = {
    CURSOR_LOST: 'cursor_lost',
    FOCUS_LOST: 'focus_lost',
    TAB_LOST: 'tab_lost',
    FULL_SCREEN_LOST: 'full_screen_lost',
    FULL_SCREEN_ERROR: 'full_screen_error',
};

/**
 * Options defined as vanilla object until we can support TypeScript interfaces.
 */
export const UseMonitorBrowserOptions = {
    // Callback invoked when an error occurs and test is halted
    onError: null,
    // Enable fullscreen monitoring
    fullscreenEnabled: true,
    // Delay in milliseconds after entering fullscreen and monitoring is enabled
    fullscreenDelayAfterEnter: 1000,
    // Delay in milliseconds before checking window dimensions whilst in fullscreen
    fullscreenDelayBeforeResize: 300,
    // Delay in milliseconds after determining window dimensions changed whilst in fullscreen
    fullscreenDelayAfterResize: 1000,
    // Enable window focus monitoring
    focusedEnabled: true,
    // Enable document visibility monitoring
    visibleEnabled: true,
    // Enable mouse outside monitoring
    outsideEnabled: true,
    // Delay in seconds before halting when losing focus
    focusedDelay: 5,
    // Alternative delay in seconds before halting after a losing focus error
    focusedDelayAfterError: 3,
    // Whether to reset the timeout when focus is returned
    focusedTimeoutReset: false,
    // Delay in seconds before halting when mouse is outside
    outsideDelay: 5,
    // Alternative delay in seconds before halting after a mouse is outside error
    outsideDelayAfterError: 3,
    // Whether to reset the timeout when mouse is returned
    outsideTimeoutReset: false,
}

/**
 * Reactively monitor various browser events.
 *
 * @param options
 */
export function useMonitorBrowser(options = {}) {
    options = {...UseMonitorBrowserOptions, ...options};

    const fullscreenEnabled = ref(options.fullscreenEnabled);
    const focusedEnabled = ref(options.focusedEnabled);
    const visibleEnabled = ref(options.visibleEnabled);
    const outsideEnabled = ref(options.outsideEnabled);

    const focusedDelay = ref(options.focusedDelay);
    const focusedTimeout = ref(0);
    const outsideDelay = ref(options.outsideDelay);
    const outsideTimeout = ref(0);

    const windowSize = ref({width: 0, height: 0});
    const fullscreenSize = ref({width: 0, height: 0});

    const isMonitoring = ref(false);
    const isHalted = ref(false);
    const isVisible = ref(true);
    const isFocused = useWindowFocus();
    const isOutside = useMouseOut();
    const visibility = useDocumentVisibility();
    const isFullScreenModeStarting = ref(false);

    const {
        isSupported: isFullscreenSupported,
        isFullscreen: isFullscreen,
        enter: enterFullscreen,
        exit: exitFullscreen,
        toggle: toggleFullscreen,
    } = useFullscreen();

    const {
        browserEvents,
        logCursorLost,
        logFocusLost,
        logTabLost,
        logFullScreenLost,
    } = useMonitorLogging();

    let focusedCount = 0;
    let outsideCount = 0;
    let windowResizeTimeout = null;

    watch(isFullscreen, (current) => {
        if (!isMonitoring.value || !fullscreenEnabled.value || isHalted.value) {
            return;
        }

        if (!current) {
            console.log('before fullscreen lost: ', {isMonitoring, visibleEnabled})
            haltWithError(MonitorBrowserError.FULL_SCREEN_LOST);
            logFullScreenLost().then(() => {
            });
        }
    }, {immediate: true});

    watch(visibility, (current) => {
        if (!isMonitoring.value || !visibleEnabled.value || isHalted.value || isFullScreenModeStarting.value) {
            return;
        }

        isVisible.value = current.toString() === 'visible';


        if (!isVisible.value) {
            console.log('before tab lost: ', {isMonitoring, visibleEnabled})
            haltWithError(MonitorBrowserError.TAB_LOST);
            logTabLost().then(() => {
            });
        }
    }, {immediate: true});

    watch(isOutside, (current) => {
        if (current) {
            maybeHaltOnOutside();
        }
    });

    watch(isFocused, (current) => {
        if (!current) {
            maybeHaltOnFocused();
        }
    });

    function windowResized() {
        if (!isMonitoring.value || !fullscreenEnabled.value || !isFullscreen.value) {
            return;
        }
        try {
            exitFullscreen().then(() => {
            });
        } catch (error) {
        }
    }

    /**
     * Under some unique circumstances, it's possible to exit fullscreen without the
     * browser (Chrome) detecting it, when a user minimises the browser on a Mac by using
     * the green minimise button on the browser instead of pressing the ESC key. We found
     * that switching back and forth between Desktops also played a part in this bug.
     * To work around this problem, we listen for when the browser window is resized whilst
     * fullscreen is enabled, we check the window dimensions against the dimensions
     * saved when entering fullscreen, if they are different we manually exit fullscreen.
     * In turn generating an error, halting the test and forcing the user to re-enter
     * fullscreen - going full circle.
     */
    useEventListener('resize', () => {
        setTimeout(() => {
            windowSize.value = {
                width: window.outerWidth,
                height: window.outerHeight,
            };

            if (windowResizeTimeout) {
                clearTimeout(windowResizeTimeout);
            }

            if (!isMonitoring.value || !fullscreenEnabled.value || !isFullscreen.value) {
                return;
            }

            if (
                fullscreenSize.value.width !== windowSize.value.width ||
                fullscreenSize.value.height !== windowSize.value.height
            ) {
                windowResizeTimeout = setTimeout(windowResized, options.fullscreenDelayAfterResize);
            }
        }, options.fullscreenDelayBeforeResize)
    }, {passive: true});

    function haltWithError(type) {
        let reason = 'We detected you leaving the test';
        let message = 'Click on the button below to continue your Test';

        if (type === MonitorBrowserError.FULL_SCREEN_ERROR) {
            reason = 'You have left full screen';
        }

        const error = {
            type: type,
            reason: reason,
            message: message,
        };

        isHalted.value = true;
        options.onError?.(error);
    }

    function maybeHaltOnFocused() {
        if (!isMonitoring.value || isHalted.value) {
            return;
        }

        if (!focusedEnabled.value || isFocused.value) {
            if (options.focusedTimeoutReset) {
                focusedCount = 0;
                focusedTimeout.value = 0;
            }
            isHalted.value = false;
            return;
        }

        focusedCount += 1;
        const interval = 100;
        const elapsed = interval * focusedCount;
        const timeout = focusedDelay.value * 1000;
        focusedTimeout.value = Math.min(Math.round(elapsed / timeout * 100), 100);

        if (focusedTimeout.value < 100) {
            useTimeoutFn(() => {
                maybeHaltOnFocused();
            }, interval)
        } else {
            haltWithError(MonitorBrowserError.FOCUS_LOST);
            focusedCount = 0;
            focusedTimeout.value = 0;
            focusedDelay.value = options.focusedDelayAfterError;
            logFocusLost().then(() => {
            });
        }
    }

    function maybeHaltOnOutside() {
        if (!isMonitoring.value || isHalted.value) {
            return;
        }

        if (!outsideEnabled.value || !isOutside.value) {
            if (options.outsideTimeoutReset) {
                outsideCount = 0;
                outsideTimeout.value = 0;
            }
            isHalted.value = false;
            return;
        }

        outsideCount += 1;
        const interval = 100;
        const elapsed = interval * outsideCount;
        const timeout = outsideDelay.value * 1000;
        outsideTimeout.value = Math.min(Math.round(elapsed / timeout * 100), 100);

        if (outsideTimeout.value < 100) {
            useTimeoutFn(() => {
                maybeHaltOnOutside();
            }, interval)
        } else {
            haltWithError(MonitorBrowserError.CURSOR_LOST);
            outsideCount = 0;
            outsideDelay.value = options.outsideDelayAfterError;
            logCursorLost().then(() => {
            });
        }
    }

    function startMonitoring() {
        isHalted.value = false;
        isFocused.value = true;
        focusedCount = 0;
        outsideCount = 0;
        focusedTimeout.value = 0;
        outsideTimeout.value = 0;
        if (fullscreenEnabled.value && !isFullscreen.value) {
            isFullScreenModeStarting.value = true;
            enterFullscreen().then(() => {
                setTimeout(() => {
                    fullscreenSize.value = windowSize.value;
                    isMonitoring.value = true;
                    isFullScreenModeStarting.value = false;
                }, options.fullscreenDelayAfterEnter);
            }).catch(error => {
                haltWithError(MonitorBrowserError.FULL_SCREEN_ERROR);
            });
        } else {
            fullscreenSize.value = windowSize.value;
            isMonitoring.value = true;
        }
    }

    function resumeMonitoring() {
        startMonitoring();
    }

    function stopMonitoring() {
        isMonitoring.value = false;
        isHalted.value = false;
        focusedTimeout.value = 0;
        outsideTimeout.value = 0;
        if (fullscreenEnabled.value) {
            exitFullscreen().then(() => {
            });
        }
    }

    return {
        fullscreenEnabled,
        focusedEnabled,
        visibleEnabled,
        outsideEnabled,
        focusedDelay,
        focusedTimeout,
        outsideDelay,
        outsideTimeout,
        isMonitoring,
        isHalted,
        isFocused,
        isVisible,
        isOutside,
        isFullscreen,
        isFullscreenSupported,
        enterFullscreen,
        exitFullscreen,
        toggleFullscreen,
        browserEvents,
        startMonitoring,
        resumeMonitoring,
        stopMonitoring,
    }
}